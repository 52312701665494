// Skills Icons
import { FaAndroid, FaLaravel, FaReact, FaSwift } from "react-icons/fa"
import { FcAndroidOs } from "react-icons/fc";
import { DiApple, DiChrome } from "react-icons/di";
import { AiFillChrome } from "react-icons/ai";
import { SiFlutter, SiFirebase, SiLinkedin } from "react-icons/Si"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  logo: "cyapp.dev",
  //   Header Details ---------------------
  name: "CY",
  headerTagline: [
    "Develop Mobile App",
    "With ❤️",
  ],
  //   Header Paragraph❤️
  headerParagraph:
    "#OpenToWork",

  //Contact Email
  contactEmail: "cylimyuan@gmail.com",
  connectLink: "https://www.linkedin.com/in/cyappdev",

  // End Header Details -----------------------

  products: [
    {
      hidden: true,
    },
    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/goodbuy-1519114517368.appspot.com/o/dev%2Funnamed-2.png?alt=media&token=13200903-42be-42ba-915f-4bd735e8e649",
      title: "goodbuy.my",
      category: "Online Grocery",
      online: false,
      links: [
        {
          "icon": AiFillChrome,
          "url": "https://goodbuy.cyapp.dev"
        },
        {
          "icon": FcAndroidOs,
          "url": "https://play.google.com/store/apps/details?id=dev.cyapp.goodbuy"
        }
      ]
    },
  ],

  // Work Section ------------------------
  projects: [
    {
      imageSrc: "https://firebasestorage.googleapis.com/v0/b/goodbuy-1519114517368.appspot.com/o/dev%2Forderlah_logo_640.png?alt=media&token=db78720b-6a5a-4ed5-9fa1-23583b5af5a7",
      title: "Order-lah",
      category: "F&B",
      source: "Good Friends iCreation Sdn Bhd",
      online: true,
      links: [
        {
          "icon": DiChrome,
          "url": "https://s1.order-lah.com/home"
        }
      ]
    },
    {
      imageSrc:
        "https://play-lh.googleusercontent.com/_3KbEJhx4bLskLc_50LaO_E5Cziefi6DG11gXNde4ZXiyunXJL2JiMcTdBrjdl902jg=s180",
      title: "QQPrinter",
      category: "Online Casino Accounting System",
      source: "undisclosed",
      online: false
    },
    {
      imageSrc:
        "https://is1-ssl.mzstatic.com/image/thumb/Purple117/v4/de/d8/04/ded80413-8dc0-1c22-1769-1a6f18da3358/pr_source.png/300x300bb.png",
      title: "Limo Taxi",
      category: "E-Hailing",
      source: "Good Friends iCreation Sdn Bhd",
      online: false
    },
    {
      imageSrc:
        "https://is1-ssl.mzstatic.com/image/thumb/Purple118/v4/1f/e7/40/1fe7404f-7d13-33c2-d0ea-33e8abba007b/AppIcon-1x_U007emarketing-85-220-0-6.png/300x300bb.png",
      title: "Job Cubic",
      category: "Job Portal",
      source: "Good Friends iCreation Sdn Bhd",
      online: true,
      links: [
        {
          "icon": DiApple,
          "url": "https://apps.apple.com/my/app/job-cubic/id1425609171#?platform=iphone"
        }
      ]
    },
    {
      imageSrc:
        "https://is4-ssl.mzstatic.com/image/thumb/Purple128/v4/02/ea/fb/02eafbbf-1aca-83f3-889a-4f41e3b809a2/mzl.kpqiyjbh.png/300x300bb.png",
      title: "iFan99",
      category: "Music",
      source: "Good Friends iCreation Sdn Bhd",
      online: false
    },
    {
      imageSrc:
        "https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/44/89/2c/44892c14-fc0f-9294-33b0-f423fa922c38/AppIcon-1x_U007emarketing-0-7-0-0-85-220.png/300x300bb.png",
      title: "NAWABS",
      category: "Statistic & Report",
      source: "Good Friends iCreation Sdn Bhd",
      online: true,
      links: [
        {
          "icon": FcAndroidOs,
          "url": "https://play.google.com/store/apps/details?id=com.goodfriends.nawabsapp"
        },
        {
          "icon": DiApple,
          "url": "https://apps.apple.com/my/app/nawabs/id1524493906#?platform=iphone"
        }
      ]
    },
    {
      imageSrc: "https://play-lh.googleusercontent.com/RKWWdwQ6l-cu41KVWleLmaADYbSumUVihF4ixgWQu79GV3ntI3BBD2xJvI1xglVRzK4=s180",
      title: "DRS. Wong & Partners",
      category: "Loyalty System",
      source: "GoGo Empire Sdn Bhd",
      online: true,
      links: [
        {
          "icon": FcAndroidOs,
          "url": "https://play.google.com/store/apps/details?id=com.gogoempire.drwong"
        },
        {
          "icon": DiApple,
          "url": "https://apps.apple.com/my/app/drs-wong-partners/id1445983199"
        }
      ]
    },
    {
      imageSrc: "https://is2-ssl.mzstatic.com/image/thumb/Purple115/v4/9a/8e/59/9a8e59af-cf36-104b-cbfb-35ef59cc3069/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/300x300bb.png",
      title: "Locum",
      category: "Medical",
      source: "GoGo Empire Sdn Bhd",
      online: true,
      links: [
        {
          "icon": FcAndroidOs,
          "url": "https://play.google.com/store/apps/details?id=com.weassist.locum"
        },
        {
          "icon": DiApple,
          "url": "https://apps.apple.com/my/app/locum-apps-user/id1499761211"
        }
      ]
    },
    {
      imageSrc: "https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/ab/97/a8/ab97a89a-9fa8-7c8e-a4e9-6077ef0db847/AppIcon-locum-1x_U007emarketing-0-7-0-0-85-220.png/300x300bb.png",
      title: "Locum Staff",
      category: "Medical",
      source: "GoGo Empire Sdn Bhd",
      online: true,
      links: [
        {
          "icon": FcAndroidOs,
          "url": "https://play.google.com/store/apps/details?id=com.weassist.locum.staff"
        },
        {
          "icon": DiApple,
          "url": "https://apps.apple.com/my/app/locum-apps/id1500197723"
        }
      ]
    }
  ],
  // End Work Section -----------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      icon: FaSwift,
      title: "Native iOS",
      para: "iOS App development with Swift / Objective c",
    },
    {
      icon: SiFlutter,
      title: "Flutter",
      para: "Hybrid app development",
    },
    {
      icon: FaAndroid,
      title: "Native Android",
      para: "Android App developement with Kotlin",
    },
    {
      icon: FaReact,
      title: "ReactJS",
      para: "Javascript frontend framework for creating SPA",
    },
    {
      icon: FaLaravel,
      title: "Laravel",
      para: "PHP framework for backend development",
    },
    {
      icon: SiFirebase,
      title: "Firebase",
      para: "Google tools for ease mobile and web development",
    },
  ],

  // End Skills Section --------------------------

  // About Secton --------------
  aboutParaOne: "2016.",
  aboutParaTwo: "2018 - 2019.",
  aboutParaThree: "2020 - Present.",
  // aboutImage: "https://lh3.googleusercontent.com/-Fvvu1pXj_CI/V2NImQ09DrI/AAAAAAAAAUc/VGRX9pJJC9EcfcYFhqZLZSBKwD5F5MaSgCEwYBhgLKtQDAL1OcqwbhcFFmRSh8t9XPRvP5CkkReF-VD3uIRd-4bamgRC8SxQJjvC9pTTWuNMPpaGHw4kHv9q92yYBjoL2fNMZIKak7lMDKzKjSDWsHK2RHgNNGXICoF70JrjHBvwJKLzXwSmDZ00z4CIKwZhwxZN2rqg2-4hbu_XRXGZHjfZusX1Mj5y9J4FX6AAqXnTTY0DKOr-9WwmnPz5hD97jKjWQ1AxAuLEB1bnmDRmiCsp_y6fOb8tYQg8Arz1avhtixWQeNWsm3x05x2jJTzOQVaJemGBv_dABCDMABVMwEuR88Ikyg1VsNz8sKgAreqDKdRCMePO_y_HT5OHyJuAZZXgJm4Wk3j6d4ZogCy7Z13l7QZN-bCnDTNohlTagVT2HVY4xBjibvvWw0FDCWbgMwNzmyKr7uHSBnENkhrD1lF5tyr0-W1QarXhCYehvM_TwF38YpBVE8W-_yB7dypHQoSXIILyx5aNxvGc3WNVwmSxSrREu9hMDOFspJipqsjiqTbGUER9P9F9Q9tSCq79x4ezN6lfB_5tTS0A1GecojlaogEa8AAe44ZujwzZa-M_eaM6cNqWoqb-EbHoZlvuGzZM7BTwZhy4PjjXXBKJM3rbh-jo-MISVkIUG/w278-h280-p/IMG-20120915-WA0000.jpg",

  //   End About Section ---------------------

  //   Promotion Section --------------------------

  promotionHeading: "",
  promotionPara: "",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    { img: SiLinkedin, url: "https://www.linkedin.com/in/cyappdev" },
  ],

  // End Contact Section ---------------
}
